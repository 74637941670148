<template>
  <div :class=this.textAlign>
    <ImgCard
      v-if="this.cardData.metadata.type.key === 'image'"
      :title="this.notitle ? '' : this.cardData.title"
      :label=this.cardData.metadata.label
      :text=this.cardData.content
      :sub-title=this.cardData.metadata.caption
      :image=this.cardData.metadata.media_id
      :link=this.cardData.metadata.link
      :height="this.cardData.metadata.height + ''"
      :width="this.cardData.metadata.width + ''"
      :action-label=this.cardData.metadata.action_label
      :align=this.cardData.metadata.align>
    </ImgCard>
    <VideoCard
      :title="this.notitle ? '' : this.cardData.title"
      v-if="this.cardData.metadata.type.key === 'video'"
      autoplay="this.short"
      :type=this.cardData.metadata.type.key
      :label=this.cardData.metadata.label
      :content=this.cardData.content
      :height="this.cardData.metadata.height"
      :width="this.cardData.metadata.width"
      :sub-title=this.cardData.metadata.caption
      :video-id=this.cardData.metadata.media_id
      :link=this.cardData.metadata.link
      :action-label=this.cardData.metadata.action_label
      :align=this.cardData.metadata.align
    >
    </VideoCard>
    <CosmicVideoCard
      v-if="this.cardData.metadata.type.key === 'cosmic-video'"
      v-bind:title="this.cardData.title"
      v-bind:content="this.cardData.content"
      v-bind:video-id="this.cardData.metadata.media_id.split('|')[0]"
      v-bind:poster="this.cardData.metadata.media_id.split('|')[1]"
      :height="this.cardData.metadata.height"
      :width="this.cardData.metadata.width"
      :sub-title=this.cardData.metadata.caption
      :link=this.cardData.metadata.link
      :action-label=this.cardData.metadata.action_label
      :align=this.cardData.metadata.align
    >
    </CosmicVideoCard>
    <InstagramCard
      :title="this.notitle ? '' : this.cardData.title"
      v-if="this.cardData.metadata.type.key === 'instagram'"
      autoplay="this.short"
      :label=this.cardData.metadata.label
      :content=this.cardData.content
      :height="this.cardData.metadata.height"
      :width="this.cardData.metadata.width"
      :sub-title=this.cardData.metadata.caption
      :post-id=this.cardData.metadata.media_id
      :link=this.cardData.metadata.link
      :action-label=this.cardData.metadata.action_label
      :align=this.cardData.metadata.align
    >
    </InstagramCard>
  </div>
</template>
<script>
import { COSMIC_BASE_URL } from '@/common/request';
const CosmicVideoCard = () => import( "@/components/Cards/CosmicVideoCard");
const VideoCard = () => import('@/components/Cards/VideoCard');
const ImgCard = () => import('@/components/Cards/ImgCard');
const InstagramCard = () => import('@/components/Cards/InstagramCard');

export default {
  name: 'DynamicCard',
  components: {CosmicVideoCard, InstagramCard, VideoCard, ImgCard},
  data: () => {
    return {
      cardData: {metadata: {type: {}}},
    }
  },
  created() {
    fetch(
      encodeURI(
        `${COSMIC_BASE_URL}&props=title,slug,content,metadata&query={"type":"cards","slug": "${this.slug}"}`
      )
    ).then((response) => {
        response.json().then((res) => {
          this.cardData = res.objects[0];
        })
      }
    );
  },
  props: {
    slug: {
      type: String,
      description: 'Card Slug',
    },
    title: {
      type: String,
      description: 'Card title',
      default: ''
    },
    notitle: {
      type: Boolean,
      description: 'Do not use Title',
      default: false
    },
    short: {
      type: Boolean,
      description: 'Is Short',
      default: false
    },
    textAlign: {
      type: String,
      default: 'text-center',
      description: 'Text Alignment',
    },
  },
};
</script>


